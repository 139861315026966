import React, { useState } from 'react';
import {
  MenuFoldOutlined,
  MenuUnfoldOutlined,
  FormatPainterOutlined,
} from '@ant-design/icons';
import { Layout, Menu, Button, theme, message, FloatButton, Avatar, Space } from 'antd';
import CodeMirror from '@uiw/react-codemirror';
import { githubLight } from '@uiw/codemirror-theme-github';
import { langs } from '@uiw/codemirror-extensions-langs';
import JsonEditor from './jsonEditor';

const { Header, Sider, Content, Footer } = Layout;
const url = 'https://projson.com/favicon.ico';

const App = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [messageApi, contextHolder] = message.useMessage();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const [jsonValue, setValue] = React.useState("");
  const textOnChange = React.useCallback((val, viewUpdate) => {
    setValue(val);
  }, []);

  var json = {
    'array': [1, 2, 3],
    'boolean': true,
    'null': null,
    'number': 123,
    'object': { 'a': 'b', 'c': 'd' },
    'string': 'projson.com'
  }
  const [jsonObject, setJson] = useState(json);

  function jsonTransfer() {
    json = jsonValue;
    try {
      var jsonObj = JSON.parse(json);
      var beautifiedJson = JSON.stringify(jsonObj, null, 4);
      messageApi.success("JSON format success");
      setValue(beautifiedJson);
      setJson(jsonObj);
    } catch (error) {
      messageApi.error("JSON validate fail：" + error.message);
    }
  }

  function jsonClear() {
    setValue("");
    setJson({});
  }

  function jsonCompress() {
    json = jsonValue;
    try {
      var jsonObj = JSON.parse(json);
      var compressedJson = JSON.stringify(jsonObj);
      messageApi.success("JSON compress success");
      setValue(compressedJson);
      setJson(jsonObj);
    } catch (error) {
      messageApi.error("JSON validate fail：" + error.message);
    }
  }

  return (
    <Layout>
      <Sider trigger={null} collapsible collapsed={collapsed}>
        <div className="demo-logo-vertical" />
        <Menu
          theme="dark"
          mode="inline"
          defaultSelectedKeys={['1']}
          items={[
            {
              key: '1',
              icon: <FormatPainterOutlined />,
              label: 'JSON Formatter',
            },
          ]}
        />
      </Sider>
      <Layout>
        <Header
          style={{
            padding: 0,
            background: colorBgContainer,
          }}
        >
          <Button
            type="text"
            icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
            onClick={() => setCollapsed(!collapsed)}
            style={{
              fontSize: '16px',
              width: 64,
              height: 64,
            }}
          />
          <Space size={16} wrap>
            <Avatar
              src={url}
              shape='square'
              size={'large'}
              alt='projson.com'
            />
            <span>projson.com</span>
          </Space>
        </Header>
        <Content
          style={{
            margin: '24px 16px',
            padding: 24,
            minHeight: 280,
            background: colorBgContainer,
          }}
        >
          <h1>ProJSON - JSON Formatter</h1>
          <p>To format your JSON, just copy + paste it below, and then click the button "format".</p>
          <div className='jsonInputArea'>
            <CodeMirror
              value={jsonValue}
              theme={githubLight}
              mode="application/json"
              height="400px"
              lineNumbers={true}
              maxHighlightLength={Infinity}
              extensions={[langs.json()]}
              onChange={textOnChange}
            />
          </div>
          <br />
          {contextHolder}
          <div className='transferButton'>
            <Space>
              <Button type="primary" onClick={jsonTransfer}>format</Button>
              <Button onClick={jsonClear}>clear</Button>
              <Button onClick={jsonCompress}>compress</Button>
            </Space>
          </div>
          <br />
          <div className='jsonOutputArea'>
            <JsonEditor json={jsonObject} />
          </div>
          <FloatButton.BackTop />

        </Content>
        <Footer
          style={{
            textAlign: 'center',
          }}
        >
          Copyright © 2023 • projson.com
        </Footer>
      </Layout>
    </Layout>
  );
};
export default App;